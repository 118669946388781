import React from 'react'
import { IPrompt } from 'interfaces/Prompt'
import { useTranslation } from 'react-i18next'
import { PromptContentCard } from 'components/prompts/PromptContentCard'
import { generatePath, useNavigate } from 'react-router'
import { ROUTE_PATHS } from 'constants/routePath'
import { useParams } from 'react-router-dom'
import { useGetAllModelesQuery } from 'api/referentielModeles'
import { TemperatureSlider } from 'components/common/TemperatureSlider'
import Loader from 'components/common/Loader'

export const PromptContent: React.FC<{
  prompt: IPrompt
}> = ({ prompt }) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { idApplication } = useParams()
  const { data: modeles, isLoading } = useGetAllModelesQuery()
  const defaultModele = modeles?.find((m) => m.defaut)?.label ?? ''
  const modele =
    modeles?.find((m) => m.nom === prompt.modele)?.label ?? defaultModele

  const manageDistribution = () => {
    navigate(
      generatePath(ROUTE_PATHS.DISTRIBUTION, {
        idApplication,
      })
    )
  }

  if (isLoading) {
    return (
      <div className="w-full h-full flex items-center justify-center">
        <Loader pageCenter />
      </div>
    )
  }

  return (
    <>
      <div className="content-prompt w-full">
        <div className="content-prompt-fields">
          <div className="flex flex-row items-center leading-8">
            <div className="label">{t('prompts.etat')}</div>
            <div>
              <span className={`state ${prompt.actif ? 'actif' : 'inactif'}`}>
                {prompt.actif ? t('prompts.actif') : t('prompts.inactif')}
              </span>
              <span className="manage-link" onClick={manageDistribution}>
                {t('prompts.manageDistrib')}
              </span>
            </div>
          </div>
          <div className="flex flex-row items-center leading-8">
            <div className="label">{t('prompts.form.modeleDispoLabel')}</div>
            <div>{modele}</div>
          </div>
          <div className="flex flex-row items-center leading-8">
            <div className="label">{t('prompts.form.temperatureLabel')}</div>
            <div>
              <TemperatureSlider value={prompt.temperature ?? 0.5} />
            </div>
          </div>
          <div className="flex flex-row pt-30px w-full">
            <div className="w-full">
              <PromptContentCard prompt={prompt} />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
