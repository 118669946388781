export const ROUTE_PATHS = {
  DASHBOARD: '/',
  DISTRIBUTION: '/applications/:idApplication/prompts/distribution',
  KPI: '/applications/:idApplication/prompts/kpi',
  ERROR: '/error',
  PROFILES: '/profiles',
  PROFILES_CREATE: '/profiles/create',
  PROFILES_EDIT: '/profiles/:idProfile/edit',
  PROMPT: '/applications/:idApplication/prompts/:idPrompt',
  PROMPT_CREATE: '/applications/:idApplication/prompts/create',
  PROMPT_EDIT: '/applications/:idApplication/prompts/:idPrompt/edit',
  PROMPTS: '/applications/:idApplication/prompts',
  TAG_ADMIN: '/tags',
}
