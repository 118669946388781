import React from 'react'
import { ITagUser } from 'interfaces/User'
import { useTranslation } from 'react-i18next'
import {
  ROLE_ADMIN_VALUE,
  ROLE_DESACTIVE_VALUE,
  ROLE_USER_VALUE,
} from 'constants/role'
import { FieldArrayWithId, UseFieldArrayUpdate } from 'react-hook-form'
import { ProfileForm } from 'hooks/useEditProfile'

export const ProfileRadio: React.FC<{
  currentUserTagAdmin: (ITagUser | undefined)[] | undefined
  fields: FieldArrayWithId<ProfileForm, 'roles', 'id'>[]
  update: UseFieldArrayUpdate<ProfileForm, 'roles'>
  disabled: boolean
}> = ({ currentUserTagAdmin, fields, update, disabled }) => {
  const { t } = useTranslation()
  const roles = [ROLE_DESACTIVE_VALUE, ROLE_USER_VALUE, ROLE_ADMIN_VALUE]
  return (
    <table className="profile-role-edition">
      <thead>
        <tr>
          <th className="font-medium">{t('profiles.tagLabel')}</th>
          <th className="font-light text-gray-1250">
            {t('profiles.roleDesactiveLabel')}
          </th>
          <th className="font-light">{t('profiles.userLabel')}</th>
          <th className="font-light text-blue-1350">
            {t('profiles.adminLabel')}
          </th>
        </tr>
      </thead>
      <tbody>
        {fields.map((field: any, fieldIndex: number) => (
          <tr key={fieldIndex} className="h-[50px]">
            <td className="my-[10px] flex items-center justify-center">
              <div className="tag-role">
                {
                  currentUserTagAdmin?.find((tag) => tag?.id === field.idTag)
                    ?.nomTag
                }
              </div>
            </td>
            {roles.map((role, index) => (
              <td key={index} className="text-center">
                <input
                  type="radio"
                  className="profile-radio"
                  value={role}
                  disabled={disabled}
                  checked={field.idRole === role}
                  onChange={() => {
                    update(fieldIndex, {
                      idTag: field.idTag,
                      idRole: role,
                    })
                  }}
                />
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  )
}
