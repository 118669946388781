import { IKpiRequest, IKpiResponse } from 'interfaces/Kpi'
import {
  endOfMonth,
  endOfWeek,
  format,
  intervalToDuration,
  startOfMonth,
  startOfWeek,
  subMonths,
  subWeeks,
  subYears,
} from 'date-fns'
import { PERIOD } from 'constants/period'

export const usePromptKpi = () => {
  const transformData = (
    data: Record<string, IKpiResponse[]>,
    metric: string,
    period: string
  ) => {
    const result: Record<string, any> = {}
    const isShortPeriod = period === PERIOD.HEBDO || period === PERIOD.MENSUELLE

    Object.entries(data).forEach(([promptId, values]) => {
      values.forEach((item) => {
        const key = isShortPeriod ? item.date : item.annee_mois
        if (key) {
          if (!result[key]) {
            result[key] = { date: key }
          }
          const rawValue = item[metric as keyof IKpiResponse]
          const value =
            typeof rawValue === 'number'
              ? rawValue
              : parseFloat(rawValue as string) || 0
          result[key][promptId] = value
          result[key].total = result[key].total || 0
          result[key].count = result[key].count || 0
          result[key].total = value + (result[key].total || 0)
          result[key].count = result[key].count + 1
        }
      })
    })

    return Object.values(result).map((item) => ({
      ...item,
      date: format(
        new Date(
          item.date.substring(0, 4),
          item.date.substring(4, 6) - 1,
          isShortPeriod ? item.date.substring(6) : 1
        ),
        isShortPeriod ? 'dd/MM' : 'MM/yyyy'
      ),
      average: item.count > 0 ? item.total / item.count : 0,
    }))
  }

  const checkIfEmpty = (data: Record<string, IKpiResponse[]>): boolean => {
    if (Object.keys(data).length === 0) {
      return true
    }
    return Object.values(data).every((array) => array.length === 0)
  }

  const getSumByField = (
    data: Record<string, IKpiResponse[]>,
    fieldName: string,
    totalField?: string
  ): number => {
    return Object.values(data)
      .flat()
      .reduce((sum, item) => {
        const value = item[fieldName as keyof IKpiResponse]
        const numericValue =
          typeof value === 'number' ? value : parseFloat(value ?? '0')
        if (totalField) {
          const total = item[totalField as keyof IKpiResponse]
          const numericTotal =
            typeof total === 'number' ? total : parseFloat(total ?? '0')
          return sum + numericValue * numericTotal
        }
        return sum + numericValue
      }, 0)
  }

  const getBodyRequest = (
    idApplication: string,
    selectedPrompts: number[],
    period: string,
    start: Date,
    end: Date,
    isPreviousPeriod = false
  ): IKpiRequest => {
    let startDate = start
    let endDate = end

    if (isPreviousPeriod) {
      if (period === PERIOD.HEBDO) {
        const lastWeek = subWeeks(start, 1)
        startDate = startOfWeek(lastWeek, { weekStartsOn: 1 })
        endDate = endOfWeek(lastWeek, { weekStartsOn: 1 })
      } else if (period === PERIOD.MENSUELLE) {
        const lastMonth = subMonths(start, 1)
        startDate = startOfMonth(lastMonth)
        endDate = endOfMonth(lastMonth)
      } else if (period === PERIOD.SEMESTRIELLE) {
        const lastSixMonths = subMonths(start, 6)
        startDate = startOfMonth(lastSixMonths)
        endDate = endOfMonth(subMonths(end, 6))
      } else if (period === PERIOD.ANNUELLE) {
        const lastYear = subYears(start, 1)
        startDate = startOfMonth(lastYear)
        endDate = endOfMonth(subMonths(end, 12))
      }
    }

    return {
      idApplication: idApplication,
      idPrompts: selectedPrompts.map((id) => id.toString()),
      type:
        period === PERIOD.HEBDO || period === PERIOD.MENSUELLE
          ? 'JOUR'
          : 'MOIS',
      dateDebut:
        period === PERIOD.HEBDO || period === PERIOD.MENSUELLE
          ? format(startDate, 'yyyyMMdd')
          : format(startDate, 'yyyyMM'),
      dateFin:
        period === PERIOD.HEBDO || period === PERIOD.MENSUELLE
          ? format(endDate, 'yyyyMMdd')
          : format(endDate, 'yyyyMM'),
    }
  }
  const formattedTime = (milliseconds: number): string => {
    const duration = intervalToDuration({ start: 0, end: milliseconds })

    const hours = duration.hours || 0
    const minutes = duration.minutes || 0
    const secs = duration.seconds || 0

    const timeParts = []

    if (hours > 0) timeParts.push(`${hours}h`)
    if (minutes > 0) timeParts.push(`${minutes}m`)
    if (secs > 0 && hours === 0) timeParts.push(`${secs}s`)

    return timeParts.join(' ')
  }
  return {
    transformData,
    checkIfEmpty,
    getSumByField,
    getBodyRequest,
    formattedTime,
  }
}
