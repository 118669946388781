import { baseApi } from 'api/base'
import {
  IAskBody,
  IAskResponse,
  IPromptAskBody,
  IPromptAskResponse,
} from 'interfaces/Ask'
import { generatePath } from 'react-router'

const ASK_ENDPOINT = '/ask'
const ASK_PROMPT_ENDPOINT = '/iaccess/ask'

const askApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    ask: builder.mutation<IAskResponse, IAskBody>({
      query: (body) => ({
        url: ASK_ENDPOINT,
        method: 'POST',
        body: body,
      }),
      invalidatesTags: [],
    }),
    askPrompt: builder.mutation<IPromptAskResponse, IPromptAskBody>({
      query: (body) => ({
        url: generatePath(ASK_PROMPT_ENDPOINT),
        method: 'POST',
        body: body,
      }),
    }),
  }),
})

export const { useAskMutation, useAskPromptMutation } = askApi

export { askApi }
