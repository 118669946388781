import { baseApi } from 'api/base'
import { generatePath } from 'react-router'
import { IUser } from 'interfaces/User'

const USERS_ENDPOINT = '/utilisateurs'
const USER_ENDPOINT = `${USERS_ENDPOINT}/:userId`
const USERS_ME_ROLE_ENDPOINT = '/utilisateurs/me/roles'

const userApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getAllUsers: builder.query<IUser[], void>({
      query: () => generatePath(USERS_ENDPOINT),
    }),
    getMeRoles: builder.query<IUser, void>({
      query: () => generatePath(USERS_ME_ROLE_ENDPOINT),
    }),
    getUser: builder.query<IUser, { userId: number }>({
      query: ({ userId }) =>
        generatePath(USER_ENDPOINT, {
          userId: userId.toString(),
        }),
    }),
    updateUser: builder.mutation<
      IUser,
      {
        userId: number
        email: string
        tagRoles: { idTag: number; idRole: number }[]
        superAdmin: boolean
      }
    >({
      query: ({ userId, email, tagRoles, superAdmin }) => ({
        url: generatePath(USER_ENDPOINT, {
          userId: userId.toString(),
        }),
        method: 'PATCH',
        body: { email, tagRoles, superAdmin },
      }),
    }),
    createUser: builder.mutation<
      IUser,
      {
        email: string
        tagRoles: { idTag: number; idRole: number }[]
        superAdmin: boolean
      }
    >({
      query: ({ email, tagRoles, superAdmin }) => ({
        url: generatePath(USERS_ENDPOINT),
        method: 'POST',
        body: { email, tagRoles, superAdmin },
      }),
    }),
  }),
})

export const {
  useGetAllUsersQuery,
  useGetMeRolesQuery,
  useGetUserQuery,
  useUpdateUserMutation,
  useCreateUserMutation,
} = userApi
export { userApi }
