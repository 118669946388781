import { useGetAllApplicationsQuery } from 'api/application'
import Loader from 'components/common/Loader'
import { UserCard } from 'components/layout/UserCard'
import { UseKeycloakService } from 'packages/KeycloakProvider'
import { ApplicationFilters } from 'components/dashboard/ApplicationFilters'
import React, { useContext, useEffect, useState } from 'react'
import { IApplication } from 'interfaces/Application'
import { MobileMenu } from 'components/dashboard/MobileMenu'
import { ApplicationCardListWrapper } from 'components/dashboard/ApplicationCardListWrapper'
import { FormApplicationModal } from 'components/applications/FormApplicationModal'
import { FilterModal } from 'components/dashboard/FilterModal'
import { UserCardMobile } from 'components/layout/UserCardMobile'
import { UserContext } from 'contexts/UserContext'

export const Dashboard = () => {
  const [applications, setApplications] = useState<IApplication[]>([])
  const [filtersApplied, setFiltersApplied] = useState(false)
  const { data, isLoading } = useGetAllApplicationsQuery()
  const [isAppLoading, setIsAppLoading] = useState(false)
  const [open, setOpen] = useState(false)
  const [openFilter, setOpenFilter] = useState(false)
  const { user: currentUser } = useContext(UserContext)
  const tagIds = currentUser?.utilisateurTagRoles.map(
    (tagRole) => tagRole.tag.id
  )
  const accessDashboard =
    (currentUser?.superAdmin ||
      (currentUser?.utilisateurTagRoles &&
        currentUser?.utilisateurTagRoles.length > 0)) ??
    false
  const openModal = () => {
    setOpen(true)
  }
  const openFilterModal = () => {
    setOpenFilter(true)
  }
  useEffect(() => {
    if (data) {
      const apps = data.filter((app) =>
        app?.tags?.some((tag) => tagIds?.includes(tag.id))
      )
      if (currentUser?.superAdmin) {
        setApplications(data)
      } else {
        setApplications(apps)
      }
    }
  }, [isLoading])
  const keycloak = UseKeycloakService()

  if (isLoading) {
    return (
      <div className="w-full h-full flex items-center justify-center">
        <Loader pageCenter />
      </div>
    )
  }

  if (!applications) {
    return null
  }
  return (
    applications && (
      <div className="dashboard-wrapper">
        <div className="dashboard-side">
          <UserCard username={keycloak.getName()} />
          {accessDashboard && (
            <ApplicationFilters
              setApplications={setApplications}
              setIsAppLoading={setIsAppLoading}
              setFiltersApplied={setFiltersApplied}
            />
          )}
        </div>
        <UserCardMobile username={keycloak.getName()} />
        <ApplicationCardListWrapper
          applications={applications}
          openModal={openModal}
          isLoading={isAppLoading}
          filtersApplied={filtersApplied}
          accessDashboard={accessDashboard}
        />
        <MobileMenu
          openNewAppModal={openModal}
          openFilterModal={openFilterModal}
          displayAddButton={applications.length > 0 && accessDashboard}
        />
        <FilterModal
          open={openFilter}
          setOpen={setOpenFilter}
          setApplications={setApplications}
        />
        <FormApplicationModal open={open} setOpen={setOpen} />
      </div>
    )
  )
}
