import { IUserColumn } from 'interfaces/User'
import { useTranslation } from 'react-i18next'
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table'
import { ReactComponent as NewProfileIcon } from 'assets/images/newProfileIcon.svg'
import SortableHeader from 'components/profiles/SortableHeader'
import { generatePath, useNavigate } from 'react-router'
import { ROUTE_PATHS } from 'constants/routePath'

export const ProfilesListMobile: React.FC<{ users: IUserColumn[] }> = ({
  users,
}) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const createProfile = () => {
    navigate(generatePath(ROUTE_PATHS.PROFILES_CREATE))
  }
  const editProfile = (idProfile: number) => {
    navigate(generatePath(ROUTE_PATHS.PROFILES_EDIT, { idProfile }))
  }
  const columnHelper = createColumnHelper<IUserColumn>()
  const columns = [
    columnHelper.accessor('nom', {
      header: ({ column }) => (
        <SortableHeader label={t('profiles.nom')} column={column} />
      ),
      enableSorting: true,
      cell: ({ row }) =>
        `${row.original.nom ?? ''} ${row.original.prenom ?? ''}`,
    }),
    columnHelper.accessor('email', {
      header: t('profiles.email'),
    }),
    columnHelper.accessor('role', {
      header: ({ column }) => (
        <SortableHeader label={t('profiles.role')} column={column} />
      ),
      enableSorting: true,
    }),
  ]
  const table = useReactTable({
    data: users,
    columns,
    initialState: {
      sorting: [
        {
          id: 'nom',
          desc: false,
        },
      ],
    },
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
  })

  return (
    <>
      <table className="table-profile">
        <thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <th key={header.id}>
                  {flexRender(
                    header.column.columnDef.header,
                    header.getContext()
                  )}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody>
          {table.getRowModel().rows.map((row) => (
            <tr
              key={row.id}
              className="hover:bg-gray-50"
              onClick={() => editProfile(row.original.id)}
            >
              {row.getVisibleCells().map((cell) => (
                <td key={cell.id}>
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
      <div
        className="md:hidden absolute bottom-3.5 right-3.5 flex flex-row"
        onClick={createProfile}
      >
        <NewProfileIcon />
      </div>
    </>
  )
}
