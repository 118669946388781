import { DarkThemeToggle } from 'components/layout/DarkThemeToggle'
import React, { useContext } from 'react'
import { generatePath, useNavigate } from 'react-router'
import { ReactComponent as LogoLauria } from 'assets/images/logoLauria.svg'
import { ReactComponent as IconeProfile } from 'assets/images/iconeProfile.svg'
import { UserContext } from 'contexts/UserContext'

export const LayoutHeader = () => {
  const navigate = useNavigate()
  const { isAdmin, user } = useContext(UserContext)
  const goToDashboard = () => {
    navigate(generatePath('/'))
  }
  const goToProfiles = () => {
    navigate(generatePath('/profiles'))
  }

  return (
    <div className="layout-header">
      <div>
        <LogoLauria className="logo-lauria" onClick={goToDashboard} />
      </div>
      <div className="option-header">
        <DarkThemeToggle />
        {(isAdmin || user?.superAdmin) && (
          <IconeProfile className="profile-option" onClick={goToProfiles} />
        )}
      </div>
    </div>
  )
}
