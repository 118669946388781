import { ServiceSelect } from 'components/dashboard/ServiceSelect'
import { TagSelect } from 'components/dashboard/TagSelect'
import React, {
  ChangeEvent,
  Dispatch,
  SetStateAction,
  useEffect,
  useState,
} from 'react'
import { useTranslation } from 'react-i18next'
import { IApplication } from 'interfaces/Application'
import { useGetAllApplicationsQuery } from 'api/application'
import { TagWithDeleteButton } from 'components/common/TagWithDeleteButton'

export const ApplicationFilters: React.FC<{
  setApplications: Dispatch<SetStateAction<IApplication[]>>
  setIsAppLoading: Dispatch<SetStateAction<boolean>>
  setFiltersApplied: Dispatch<SetStateAction<boolean>>
}> = ({ setApplications, setIsAppLoading, setFiltersApplied }) => {
  const [selectedTags, setSelectedTags] = useState<
    { id: number; label: string }[]
  >([])
  const { t } = useTranslation()
  const [service, setService] = useState('')
  const { data, isLoading, isFetching } = useGetAllApplicationsQuery({
    services: service ? [parseInt(service)] : [],
    tags: selectedTags.map((tag) => tag.id),
  })
  useEffect(() => {
    if (data) {
      setApplications(data)
    }
  }, [data])
  useEffect(() => {
    setIsAppLoading(isFetching || isLoading)
  }, [isLoading, isFetching])
  useEffect(() => {
    setFiltersApplied(service !== '' || selectedTags.length > 0)
  }, [service, selectedTags])

  const handleTagRemove = (id: number) => {
    setSelectedTags(
      selectedTags.filter((tag: { id: number; label: string }) => tag.id !== id)
    )
  }
  const filterAppByService = (event: ChangeEvent<HTMLSelectElement>) => {
    setService(event.target.value)
  }

  return (
    <div className="m-[30px] ">
      <div className="font-semibold text-lg text-white mb-4 dark:text-gray-1325">
        {t('applications.filter.title')}
      </div>
      <div className="flex flex-col">
        <div className="filter-card mb-30px">
          <ServiceSelect
            boldLabel
            placeholder={t('applications.filter.servicePlaceholder')}
            onChange={filterAppByService}
            value={service}
            displayDefaultOption
            required={false}
            labelKey="applications.filter.serviceLabel"
            width={250}
          />
        </div>
        <div className="filter-card">
          <TagSelect
            selectedTags={selectedTags}
            setSelectedTags={setSelectedTags}
            boldLabel
            optionLink
            width={250}
          />
          {selectedTags.length > 0 && (
            <div className="pt-[5px]">
              <div className="flex flex-wrap gap-2 mt-2 w-[300px]">
                {selectedTags.map((tag) => (
                  <TagWithDeleteButton
                    key={tag.id}
                    tag={tag}
                    onClick={handleTagRemove}
                  />
                ))}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}
