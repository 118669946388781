import { CurrentTagProvider } from 'contexts/CurrentTagContext'
import { TagsAdmin } from 'components/tags/TagsAdmin'
import { useContext } from 'react'
import { UserContext } from 'contexts/UserContext'
import { useNavigate } from 'react-router'
import { ROUTE_PATHS } from 'constants/routePath'

export const Tags = () => {
  const { user: currentUser } = useContext(UserContext)
  const navigate = useNavigate()
  if (!currentUser?.superAdmin) {
    navigate(ROUTE_PATHS.DASHBOARD)
  }
  return (
    <CurrentTagProvider>
      <TagsAdmin />
    </CurrentTagProvider>
  )
}
