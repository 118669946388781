import { useGetAllUsersQuery } from 'api/users'
import Loader from 'components/common/Loader'
import React, { useContext, useEffect } from 'react'
import { IUserColumn } from 'interfaces/User'
import { ProfilesList } from 'components/profiles/ProfilesList'
import { getRole } from 'utils/service'
import { fr } from 'date-fns/locale'
import { format } from 'date-fns'
import { useTranslation } from 'react-i18next'
import { ProfilesListMobile } from 'components/profiles/ProfilesListMobile'
import { UserContext } from 'contexts/UserContext'
import { useNavigate } from 'react-router'
import { ROUTE_PATHS } from 'constants/routePath'

export const Profiles = () => {
  const { data: users, isLoading } = useGetAllUsersQuery()
  const { t } = useTranslation()
  const navigate = useNavigate()
  const [profiles, setProfiles] = React.useState<IUserColumn[]>([])
  const { isAdmin, user } = useContext(UserContext)
  useEffect(() => {
    if (users) {
      setProfiles(
        users
          .filter((user) => !user.suppression)
          .map(
            ({
              id,
              nom,
              prenom,
              email,
              dateModification,
              utilisateurTagRoles,
            }) => ({
              id,
              nom,
              prenom,
              email,
              dateModification: format(
                new Date(dateModification),
                'dd/MM/yyyy',
                {
                  locale: fr,
                }
              ),
              role: t(`profiles.${getRole(utilisateurTagRoles)}`),
            })
          )
      )
    }
  }, [users])

  if (!isAdmin && !user?.superAdmin) {
    navigate(ROUTE_PATHS.DASHBOARD)
  }
  if (isLoading) {
    return (
      <div className="w-full h-full flex items-center justify-center">
        <Loader pageCenter />
      </div>
    )
  }
  return (
    <>
      <div className="profiles-list-wrapper md:flex hidden">
        <ProfilesList users={profiles} />
      </div>
      <div className="profiles-list-wrapper flex md:hidden">
        <ProfilesListMobile users={profiles} />
      </div>
    </>
  )
}
