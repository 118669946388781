import { KpiSideBar } from 'components/kpi/KpiSideBar'
import { KpiContent } from 'components/kpi/KpiContent'
import React, { useContext, useEffect } from 'react'
import { PeriodSelector } from 'components/kpi/PeriodSelector'
import { useTranslation } from 'react-i18next'
import Loader from 'components/common/Loader'
import { KpiPromptContext } from 'contexts/KpiPromptContext'
import { useGetKpisMutation } from 'api/kpi'
import { useParams } from 'react-router-dom'
import { useGetPromptsWithDetailsFromApplicationQuery } from 'api/prompt'
import { skipToken } from '@reduxjs/toolkit/query'
import { usePromptKpi } from 'hooks/usePromptKpi'

export const KpiPrompt = () => {
  const { t } = useTranslation()
  const { idApplication } = useParams()
  const {
    start,
    setStart,
    end,
    setEnd,
    period,
    setPeriod,
    currentDate,
    setCurrentDate,
    selectedPrompts,
    setSelectedPrompts,
    setPromptsList,
    setKpis,
    setOldKpis,
  } = useContext(KpiPromptContext)
  const periodState = { period, setPeriod }
  const { getBodyRequest } = usePromptKpi()
  const dateRange = {
    currentDate,
    setCurrentDate,
    start,
    setStart,
    end,
    setEnd,
  }
  const [getKpis, { isLoading: isLoadingKpi }] = useGetKpisMutation()
  const { data: prompts, isLoading: isLoadingPrompts } =
    useGetPromptsWithDetailsFromApplicationQuery(
      idApplication ? { applicationId: parseInt(idApplication) } : skipToken
    )

  useEffect(() => {
    if (prompts) {
      setPromptsList(prompts)
      const selected = prompts
        .filter(
          (prompt: any) => prompt.actif === true && prompt.suppression === false
        )
        .map((prompt: any) => prompt.id)
        .slice(0, 3)

      setSelectedPrompts(selected)
    }
  }, [prompts])

  useEffect(() => {
    const fetchData = async () => {
      if (idApplication && selectedPrompts.length > 0) {
        try {
          const requests = [
            getKpis(
              getBodyRequest(idApplication, selectedPrompts, period, start, end)
            ).unwrap(),
            getKpis(
              getBodyRequest(
                idApplication,
                selectedPrompts,
                period,
                start,
                end,
                true
              )
            ).unwrap(),
          ]

          const [currentKpis, previousKpis] = await Promise.all(requests)

          setKpis(currentKpis)
          setOldKpis(previousKpis)
        } catch (error) {
          console.error(
            'Erreur lors de la récupération des données KPI:',
            error
          )
        }
      }
    }
    fetchData()
  }, [idApplication, selectedPrompts, start])

  if (isLoadingPrompts) {
    return (
      <div className="w-full h-full flex items-center justify-center">
        <Loader pageCenter />
      </div>
    )
  }
  if (!prompts) {
    return null
  }
  return (
    prompts && (
      <div className="h-full w-full flex flex-col">
        <div className="kpi-subheader">
          <div className="kpi-title">{t('prompts.kpi.title')}</div>
          <div className="kpi-periode">
            <PeriodSelector periodState={periodState} dateRange={dateRange} />
          </div>
        </div>
        <div className="kpi-wrapper">
          <KpiSideBar />
          <KpiContent isLoading={isLoadingKpi} />
        </div>
      </div>
    )
  )
}
