import { IDocument } from 'interfaces/Document'
import { ReactComponent as DocumentIcon } from 'assets/images/prompts/uploadDocument.svg'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { PromptDocumentList } from 'components/prompts/PromptDocumentList'
import { FILE_EXTENSION_GROUPS, MAX_FILE_SIZE } from 'constants/general'

interface PromptDocumentsSectionProps {
  documents: IDocument[]
  setDocuments: React.Dispatch<React.SetStateAction<IDocument[]>>
  newFiles: File[]
  setNewFiles: React.Dispatch<React.SetStateAction<File[]>>
}

export const PromptDocumentsSection: React.FC<PromptDocumentsSectionProps> = ({
  documents,
  setDocuments,
  newFiles,
  setNewFiles,
}) => {
  const { t } = useTranslation()
  const [errorMessage, setErrorMessage] = useState<string | null>(null)
  const allowedExtensions = Object.values(FILE_EXTENSION_GROUPS).flat()
  const handleFileChange = (event: any) => {
    const file = event.target.files?.[0]
    if (!file) return
    const fileExtension = file.name.split('.').pop()?.toLowerCase()
    if (!allowedExtensions.includes(fileExtension || '')) {
      setErrorMessage(t('prompts.documents.invalidExtension'))
      return
    }
    if (file.size > MAX_FILE_SIZE) {
      setErrorMessage(t('prompts.documents.fileTooLarge'))
      return
    }
    const filesName = newFiles.map((file) => file.name)
    if (!filesName.includes(file.name)) {
      setDocuments([
        ...documents,
        {
          nomDocument: file.name,
          mimeType: file.type,
        },
      ])
      setNewFiles([...newFiles, file])
    }
    setErrorMessage(null)
  }

  return (
    <div className="documents-wrapper">
      <div className="w-1/4">
        <div
          className={`card-doc with-hover ${errorMessage ? 'bg-red-1100 outline outline-1 outline-red-1300' : ''}`}
        >
          <label htmlFor="file-upload" className="upload-doc-label">
            <div className="mx-[10px]">
              <DocumentIcon />
            </div>
            <div>{t('prompts.documents.attach')}</div>
          </label>
          <input
            id="file-upload"
            type="file"
            onChange={handleFileChange}
            className="hidden"
          />
        </div>
        {errorMessage && (
          <div className="text-red-1300 mt-1 text-[11px]">{errorMessage}</div>
        )}
      </div>
      <div className="w-3/4">
        <PromptDocumentList
          documents={documents}
          setDocuments={setDocuments}
          newFiles={newFiles}
          setNewFiles={setNewFiles}
          withDelete
        />
      </div>
    </div>
  )
}
