import { useTranslation } from 'react-i18next'
import React, { Dispatch, SetStateAction } from 'react'
import { ReactComponent as RefreshIcon } from 'assets/images/refreshIcon.svg'
import { ReactComponent as CloseIcon } from 'assets/images/closeIcon.svg'
import { usePromptTest } from 'hooks/usePromptTest'
import { UseFormGetValues } from 'react-hook-form'
import { PromptForm } from 'interfaces/Prompt'
import { IModele } from 'interfaces/Modele'
import { PromptTestModele } from 'components/prompts/PromptTestModele'
import { PromptTestResult } from 'components/prompts/PromptTestResult'

export const PromptTest: React.FC<{
  refreshVars: () => void
  displayTests: boolean
  setDisplayTests: Dispatch<SetStateAction<boolean>>
  variables: string[]
  getValues: UseFormGetValues<PromptForm>
  assistedMode: boolean
  modeles: IModele[] | undefined
}> = ({
  refreshVars,
  displayTests,
  setDisplayTests,
  variables,
  getValues,
  assistedMode,
  modeles,
}) => {
  const { t } = useTranslation()
  const {
    fields,
    handleSubmit,
    register,
    onSubmit,
    isLoading,
    answers,
    getFormTestValues,
    modele1Field,
  } = usePromptTest(variables, getValues, assistedMode)

  return (
    <div className="prompt-test-wrapper">
      {!displayTests ? (
        <div className="btn-prompt-test" onClick={() => setDisplayTests(true)}>
          {t('prompts.form.testButton')}
        </div>
      ) : (
        <>
          <div className="flex flex-col w-full">
            <div className="flex flex-row items-center justify-between w-full">
              <div
                className="text-left flex flex-row items-center cursor-pointer"
                onClick={() => refreshVars()}
              >
                <span className="underline">{t('prompts.form.refresh')}</span>
                <RefreshIcon className="ml-[5px]" />
              </div>
              <div
                className="ml-auto flex flex-row items-center cursor-pointer"
                onClick={() => setDisplayTests(false)}
              >
                {t('prompts.form.cancel')}
                <CloseIcon className="h-[13px] ml-[5px]" />
              </div>
            </div>
            <div className="prompt-test-fields-wrapper">
              <form onSubmit={handleSubmit(onSubmit)} className="w-full">
                <div className="flex flex-row w-full">
                  <table className="w-2/3">
                    <tbody>
                      <tr className="h-[40px]">
                        <td>{t('prompts.form.variable')}</td>
                        <td className="pl-[20px]">
                          {t('prompts.form.content')}
                        </td>
                      </tr>
                      {fields.map((field, index) => (
                        <tr key={index} className="h-[50px]">
                          <td>
                            <div className="test-var">{field.name}</div>
                          </td>
                          <td>
                            <input
                              {...register(`fields.${index}.value`)}
                              className="form-input ml-[20px] h-[40px] w-[200px]"
                              type="text"
                            />
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                  {modeles && (
                    <PromptTestModele
                      register={register}
                      modeles={modeles}
                      getFormTestValues={getFormTestValues}
                      modele1Field={modele1Field}
                    />
                  )}
                </div>
                <input
                  type="submit"
                  className="btn-launch-test"
                  value={t('prompts.form.launchTest')}
                />
              </form>
            </div>
          </div>
          <PromptTestResult
            answers={answers}
            isLoading={isLoading}
            modeles={modeles}
          />
        </>
      )}
    </div>
  )
}
