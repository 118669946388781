import { IModeleAnswer } from 'interfaces/Ask'
import { useTranslation } from 'react-i18next'
import Loader from 'components/common/Loader'
import { formatTime } from 'utils/service'
import React from 'react'
import { IModele } from 'interfaces/Modele'

export const PromptTestResult: React.FC<{
  answers: IModeleAnswer[]
  isLoading: boolean
  modeles: IModele[] | undefined
}> = ({ answers, isLoading, modeles }) => {
  const { t } = useTranslation()

  return (
    <div className="w-full flex flex-row gap-x-[20px]">
      {isLoading ? (
        <div className="prompt-test-result items-center justify-center bg-white dark:bg-gray-1600">
          <Loader center className="flex items-center justify-center w-full" />
        </div>
      ) : answers.length > 0 ? (
        answers.map((answer, index) => (
          <div
            key={index}
            className="prompt-test-result bg-white dark:bg-gray-1600"
          >
            <div className="answer-parameter">
              <div className="font-medium">
                {
                  modeles?.find((modele) => modele.nom === answer.modeleName)
                    ?.label
                }
              </div>
              <div>
                {answer.time && Math.round(answer.time * 100) / 100}
                ms&nbsp;/&nbsp;
                {Math.round(answer.answer.total_cost * 100000) / 100000}€
              </div>
            </div>
            <div>{answer.answer.answer}</div>
          </div>
        ))
      ) : (
        <div className="prompt-test-result text-gray-1250 bg-white-150 dark:bg-gray-1600">
          <div className="font-medium mb-[10px]">
            {t('prompts.form.result')}
          </div>
          <span>{t('prompts.form.noResult')}</span>
        </div>
      )}
    </div>
  )
}
