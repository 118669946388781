import React, { FC } from 'react'
import { ReactComponent as IconPagination } from 'assets/images/iconPagination.svg'

export const Pagination: FC<{
  nbPage: number
  currentPage: number
  onChangePage: (page: number) => void
}> = ({ nbPage, currentPage, onChangePage }) => {
  const pageLinks = []
  const handleClick = (page: number) => {
    onChangePage(page)
  }

  pageLinks.push(
    <li
      key="page1"
      className={currentPage === 1 ? 'selected' : ''}
      onClick={() => handleClick(1)}
    >
      <a>1</a>
    </li>
  )

  if (currentPage > 3) {
    pageLinks.push(<IconPagination key="icon1" className="text-gray-1350" />)
  }

  for (
    let i = Math.max(1, currentPage - 1);
    i <= Math.min(nbPage - 1, currentPage + 1);
    i++
  ) {
    if (i !== 1 && i !== nbPage) {
      pageLinks.push(
        <li
          key={`page${i}`}
          className={currentPage === i ? 'selected' : ''}
          onClick={() => handleClick(i)}
        >
          <a>{i}</a>
        </li>
      )
    }
  }

  if (currentPage < nbPage - 2) {
    pageLinks.push(<IconPagination key="icon2" className="text-gray-1350" />)
  }

  if (nbPage !== 1) {
    pageLinks.push(
      <li
        key={`page${nbPage}`}
        className={currentPage === nbPage ? 'selected' : ''}
        onClick={() => handleClick(nbPage)}
      >
        <a>{nbPage}</a>
      </li>
    )
  }

  return <ul className="pagination-profile">{pageLinks}</ul>
}
