import { Trans, useTranslation } from 'react-i18next'
import React from 'react'

export const ProfileDescription = () => {
  const { t } = useTranslation()
  return (
    <div className="profile-info">
      <p className="font-medium my-2">{t('profiles.infos.title')}</p>
      <p className="my-2">
        <span className="underline text-gray-1250">
          {t('profiles.roleDesactiveLabel')}
        </span>
        &nbsp;:&nbsp;{t('profiles.infos.accessDisabled')}
      </p>
      <p className="mb-2">
        <span className="underline">{t('profiles.userLabel')}</span>
        &nbsp;:&nbsp;
        <Trans
          i18nKey="profiles.infos.user"
          components={{ strong: <strong className="font-medium" /> }}
        />
      </p>
      <p>
        <span className="underline text-blue-1350">
          {t('profiles.adminLabel')}
        </span>
        &nbsp;:&nbsp;
        <Trans
          i18nKey="profiles.infos.admin"
          components={{ strong: <strong className="font-medium" /> }}
        />
      </p>
    </div>
  )
}
