import React from 'react'
import { Column } from '@tanstack/react-table'
import { ReactComponent as ChevronUp } from 'assets/images/chevronUp.svg'
import { ReactComponent as ChevronDown } from 'assets/images/chevronDown.svg'
import { ReactComponent as SortIcon } from 'assets/images/sortIcon.svg'

const SortableHeader: React.FC<{ column: Column<any, any>; label: string }> = ({
  column,
  label,
}) => {
  return (
    <div
      onClick={column.getToggleSortingHandler()}
      className="cursor-pointer flex flex-row items-center"
    >
      {label}
      {column.getIsSorted() === 'asc' && (
        <span>
          <ChevronUp className="ml-30px w-[9px]" />
        </span>
      )}
      {column.getIsSorted() === 'desc' && (
        <span>
          <ChevronDown className="ml-30px w-[9px]" />
        </span>
      )}
      {!column.getIsSorted() && (
        <span>
          {' '}
          <SortIcon className="ml-30px w-[9px]" />
        </span>
      )}
    </div>
  )
}

export default SortableHeader
