import { useFieldArray, useForm } from 'react-hook-form'
import { useParams } from 'react-router-dom'
import { skipToken } from '@reduxjs/toolkit/query'
import { useContext, useEffect, useState } from 'react'
import { UserContext } from 'contexts/UserContext'
import { ROLE_ADMIN, ROLE_DESACTIVE_VALUE } from 'constants/role'
import { generatePath, useNavigate } from 'react-router'
import { ROUTE_PATHS } from 'constants/routePath'
import {
  useCreateUserMutation,
  useGetUserQuery,
  useUpdateUserMutation,
} from 'api/users'
import { toast } from 'components/common/Toast'
import { useTranslation } from 'react-i18next'
import { useGetAllTagsQuery } from 'api/tag'
import { ITag } from 'interfaces/Tag'
import { ITagUser } from 'interfaces/User'

export type ProfileForm = {
  email: string
  superAdmin: boolean
  roles: { idTag: number; idRole: number }[]
}

export const useEditProfile = () => {
  const { idProfile } = useParams()
  const { t } = useTranslation()
  const navigate = useNavigate()
  const userId = idProfile ? parseInt(idProfile, 10) : undefined
  const { data: user, isLoading: isLoadingUser } = useGetUserQuery(
    userId ? { userId } : skipToken
  )
  const [currentUserTagAdmin, setCurrentUserTagAdmin] = useState<any>([])
  const { data: tags, isLoading: isLoadingTags } = useGetAllTagsQuery()
  const { user: currentUser } = useContext(UserContext)
  const [updateUser] = useUpdateUserMutation()
  const [createUser] = useCreateUserMutation()

  const {
    register,
    handleSubmit,
    setValue,
    control,
    formState: { errors },
    reset,
  } = useForm<ProfileForm>({
    defaultValues: {
      email: user?.email || '',
      superAdmin: user?.superAdmin || false,
      roles: [],
    },
  })
  const {
    fields: roleFields,
    append,
    update: updateRole,
  } = useFieldArray({
    control,
    name: 'roles',
  })
  useEffect(() => {
    if (currentUser?.superAdmin) {
      setCurrentUserTagAdmin(
        tags
          ?.filter((tag: ITag) => tag.actif)
          .map((tag: ITag) => {
            return { id: tag.id, nomTag: tag.nomTag }
          })
      )
    } else {
      setCurrentUserTagAdmin(
        currentUser?.utilisateurTagRoles?.map((tagRole) => {
          if (tagRole.role.role === ROLE_ADMIN) {
            return tagRole.tag
          }
        })
      )
    }
  }, [isLoadingTags, tags])
  useEffect(() => {
    reset({
      roles: [],
    })
  }, [tags])
  useEffect(() => {
    if (user?.email) {
      setValue('email', user.email)
    }
    if (user?.superAdmin) {
      setValue('superAdmin', user.superAdmin)
    }

    currentUserTagAdmin?.forEach((tag: ITagUser) => {
      if (tag) {
        const existingIndex = roleFields.findIndex(
          (field) => field.idTag === tag.id
        )
        if (existingIndex === -1) {
          append({ idTag: tag.id, idRole: getUserRole(tag.id) })
        } else {
          updateRole(existingIndex, {
            idTag: tag.id,
            idRole: getUserRole(tag.id),
          })
        }
      }
    })
  }, [currentUserTagAdmin, user, currentUser])
  const getUserRole = (tagId: number): number => {
    const role = user?.utilisateurTagRoles?.find(
      (tagRole) => tagRole.tag.id === tagId
    )
    return role?.role.id || ROLE_DESACTIVE_VALUE
  }

  const onSubmit = handleSubmit((data) => {
    if (userId) {
      updateUser({
        userId: userId,
        email: data.email,
        tagRoles: data.roles.filter(
          (role) => role.idRole !== ROLE_DESACTIVE_VALUE
        ),
        superAdmin: data.superAdmin,
      })
        .unwrap()
        .then((user) => {
          if (user.id) {
            toast.success(t('profiles.updateSuccess'))
            navigate(generatePath(ROUTE_PATHS.PROFILES))
          }
        })
        .catch((error) => {
          console.log(error)
        })
    } else {
      createUser({
        email: data.email,
        tagRoles: data.roles.filter(
          (role) => role.idRole !== ROLE_DESACTIVE_VALUE
        ),
        superAdmin: data.superAdmin,
      })
        .unwrap()
        .then((user) => {
          if (user.id) {
            toast.success(t('profiles.createSuccess'))
            navigate(generatePath(ROUTE_PATHS.PROFILES))
          }
        })
        .catch((error) => {
          console.log(error)
        })
    }
  })

  const cancelAction = (event: any) => {
    event.preventDefault()
    navigate(generatePath(ROUTE_PATHS.PROFILES))
  }

  return {
    user,
    register,
    onSubmit,
    errors,
    isLoading: isLoadingUser || isLoadingTags,
    currentUser,
    currentUserTagAdmin,
    roleFields,
    updateRole,
    cancelAction,
  }
}
