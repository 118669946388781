import { useTranslation } from 'react-i18next'
import { FormInput } from 'components/common/FormInput'
import React, { useContext, useEffect, useState } from 'react'
import { useEditProfile } from 'hooks/useEditProfile'
import { FieldLabel } from 'components/common/FieldLabel'
import Loader from 'components/common/Loader'
import { ProfileRadio } from 'components/profiles/ProfileRadio'
import { ProfileDescription } from 'components/profiles/ProfileDescription'
import { ProfileSelect } from 'components/profiles/ProfileSelect'
import { generatePath } from 'react-router'
import { ROUTE_PATHS } from 'constants/routePath'
import { NavLink } from 'react-router-dom'
import { ReactComponent as BackArrowIcon } from 'assets/images/backArrow.svg'
import { UserContext } from 'contexts/UserContext'

export const ProfileEdition = () => {
  const { t } = useTranslation()
  const { user: currentUser } = useContext(UserContext)
  const {
    user: profile,
    register,
    isLoading,
    onSubmit,
    errors,
    currentUserTagAdmin,
    cancelAction,
    roleFields,
    updateRole,
  } = useEditProfile()
  const [roleDisabled, setRoleDisabled] = useState(profile?.superAdmin ?? false)
  useEffect(() => {
    if (profile) {
      setRoleDisabled(profile.superAdmin)
    }
  }, [profile])
  const handleSuperAdminChange = () => {
    setRoleDisabled(!roleDisabled)
  }
  if (isLoading) {
    return (
      <Loader
        center
        className="h-screen flex items-center justify-center w-full"
      />
    )
  }

  return (
    <div className="profile-card">
      <div className="profile-title">
        {profile ? (
          <span className="capitalize">{`${profile.prenom ?? ''} ${profile.nom ?? ''}`}</span>
        ) : (
          t('profiles.newProfileTitle')
        )}
      </div>
      <form onSubmit={onSubmit} className="form-profile">
        <span className="md:hidden flex my-[20px] ml-[25px] text-blue-1350">
          <NavLink to={generatePath(ROUTE_PATHS.PROFILES)} className="navLink">
            <BackArrowIcon className="icon" />
            <span>{t('profiles.return')}</span>
          </NavLink>
        </span>
        <div className="form-content-profile">
          <div className="flex md:flex-row flex-col">
            <div className="flex flex-col md:w-1/2 w-full">
              <FieldLabel
                className={`h-[45px] w-[120px] flex items-center`}
                label={t('profiles.emailLabel')}
                required={true}
              />
              <FormInput
                fieldName="email"
                error={errors.email}
                register={register}
                wrapperClassName="md:w-[320px] w-full"
                inputClassName="w-full form-input"
              />
            </div>
            {currentUser?.superAdmin && (
              <div className="profile-super-admin">
                <input
                  type="checkbox"
                  id="superAdmin"
                  {...register('superAdmin')}
                  className="super-admin-checkbox"
                  onChange={handleSuperAdminChange}
                />
                <span className="font-medium ml-[10px]">
                  {t('profiles.superAdmin')}
                </span>
              </div>
            )}
          </div>
          {currentUserTagAdmin && (
            <>
              <div className="md:flex hidden flex-row w-full mt-[40px]">
                <div className="w-1/2">
                  <ProfileRadio
                    currentUserTagAdmin={currentUserTagAdmin}
                    fields={roleFields}
                    update={updateRole}
                    disabled={roleDisabled}
                  />
                </div>
                <div className="pr-[20px]">
                  <ProfileDescription />
                </div>
              </div>
              <div className="md:hidden flex flex-row w-full mt-[40px]">
                <ProfileSelect
                  currentUserTagAdmin={currentUserTagAdmin}
                  fields={roleFields}
                  update={updateRole}
                  disabled={roleDisabled}
                />
              </div>
            </>
          )}
        </div>
        <div className="profile-buttons">
          <button className="btn cancel hidden mr-30px" onClick={cancelAction}>
            {t('global.form.button.cancel')}
          </button>
          <input
            type="submit"
            className="btn default profile-btn-submit"
            value={t('profiles.submit')}
          />
        </div>
      </form>
    </div>
  )
}
