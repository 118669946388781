import { useTranslation } from 'react-i18next'
import Loader from 'components/common/Loader'
import { ApplicationFormInput } from 'components/applications/ApplicationFormInput'
import { useCreateApp } from 'hooks/useCreateApp'
import React, { useEffect, useState } from 'react'
import { ServiceSelect } from 'components/dashboard/ServiceSelect'
import { TagSelect } from 'components/dashboard/TagSelect'
import { IApplication } from 'interfaces/Application'
import { ApplicationFormInputReadonly } from 'components/applications/ApplicationFormInputReadonly'
import { TagWithDeleteButton } from 'components/common/TagWithDeleteButton'

export type ApplicationForm = {
  intitule: string
  nomTechnique: string
  idService: string
  tags: string
}
export const FormApplicationModalContent: React.FC<{
  cancelAction: (event: any) => void
  setModalOpen: (value: boolean) => void
  type?: 'add' | 'edit'
  application?: IApplication
}> = ({ cancelAction, setModalOpen, type = 'add', application }) => {
  const { t } = useTranslation()

  const [selectedTags, setSelectedTags] = useState<
    { id: number; label: string }[]
  >([])

  const { isLoading, errors, register, onSubmit, isValid, setValue } =
    useCreateApp(selectedTags, setModalOpen, application)

  useEffect(() => {
    if (application && application.tags) {
      setSelectedTags(
        application.tags.map((tag) => ({ id: tag.id, label: tag.nomTag }))
      )
    }
  }, [application])
  useEffect(() => {
    setValue(
      'tags',
      selectedTags.length > 0 ? JSON.stringify(selectedTags) : ''
    )
  }, [selectedTags])

  const handleTagRemove = (id: number) => {
    setSelectedTags(
      selectedTags.filter((tag: { id: number; label: string }) => tag.id !== id)
    )
  }

  if (isLoading) {
    return <Loader center />
  }

  return (
    <form onSubmit={onSubmit} className="form">
      <div className="grid grid-cols-1 md:grid-cols-2 gap-x-[60px]">
        <ApplicationFormInput
          label={t('applications.form.intituleLabel')}
          fieldName="intitule"
          register={register}
          error={errors?.intitule}
        />
        {type === 'add' ? (
          <ApplicationFormInput
            label={t('applications.form.nomTechniqueLabel')}
            fieldName="nomTechnique"
            register={register}
            error={errors?.nomTechnique}
          />
        ) : (
          <ApplicationFormInputReadonly
            label={t('applications.form.nomTechniqueLabel')}
            value={application?.nomTechnique}
          />
        )}
        <ServiceSelect
          error={errors?.idService}
          register={register}
          placeholder={t('applications.form.servicePlaceholder')}
        />
        <TagSelect
          error={errors?.tags}
          register={register}
          selectedTags={selectedTags}
          setSelectedTags={setSelectedTags}
          required
        />
      </div>
      {selectedTags.length > 0 && (
        <div className="tags">
          <h4>{t('applications.form.tagsAddedLabel')}</h4>
          <div className="tags-list">
            {selectedTags.map((tag) => (
              <TagWithDeleteButton
                key={tag.id}
                tag={tag}
                onClick={handleTagRemove}
              />
            ))}
          </div>
        </div>
      )}
      <div
        className={`text-right self-end mt-[40px] w-full md:block ${isValid ? '' : 'hidden'}`}
      >
        <button className="btn cancel hidden mr-30px" onClick={cancelAction}>
          {t('global.form.button.cancel')}
        </button>
        <input
          type="submit"
          className="btn default w-full md:w-auto"
          value={
            type === 'add'
              ? t('applications.form.createApp')
              : t('applications.form.edit.buttonSubmit')
          }
        />
      </div>
    </form>
  )
}
